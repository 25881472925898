/* latin-ext */
@font-face {
  font-family: '__DM_Sans_05e5f9';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_05e5f9';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_05e5f9';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_05e5f9 {font-family: '__DM_Sans_05e5f9', '__DM_Sans_Fallback_05e5f9';font-style: normal
}.__variable_05e5f9 {--font-dm_sans: '__DM_Sans_05e5f9', '__DM_Sans_Fallback_05e5f9'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/bde16c1724335d95-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/8a9e72331fecd08b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/0610ebff456d6cfc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/e3b8d441242e07fb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Nunito_6db340';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/21ed5661b47f7f6d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Nunito_Fallback_6db340';src: local("Arial");ascent-override: 99.71%;descent-override: 34.82%;line-gap-override: 0.00%;size-adjust: 101.39%
}.__className_6db340 {font-family: '__Nunito_6db340', '__Nunito_Fallback_6db340';font-style: normal
}.__variable_6db340 {--font-nunito: '__Nunito_6db340', '__Nunito_Fallback_6db340'
}

@font-face {
font-family: '__degularDisplaySemibold_a1b1ae';
src: url(/_next/static/media/71f30f57454e18a4-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplaySemibold_Fallback_a1b1ae';src: local("Arial");ascent-override: 109.89%;descent-override: 41.42%;line-gap-override: 0.00%;size-adjust: 87.63%
}.__className_a1b1ae {font-family: '__degularDisplaySemibold_a1b1ae', '__degularDisplaySemibold_Fallback_a1b1ae'
}.__variable_a1b1ae {--font-degular-display-semibold: '__degularDisplaySemibold_a1b1ae', '__degularDisplaySemibold_Fallback_a1b1ae'
}

@font-face {
font-family: '__degularDisplayBold_84618d';
src: url(/_next/static/media/25e2495778ab4d0a-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplayBold_Fallback_84618d';src: local("Arial");ascent-override: 108.87%;descent-override: 41.04%;line-gap-override: 0.00%;size-adjust: 88.45%
}.__className_84618d {font-family: '__degularDisplayBold_84618d', '__degularDisplayBold_Fallback_84618d'
}.__variable_84618d {--font-degular-display-bold: '__degularDisplayBold_84618d', '__degularDisplayBold_Fallback_84618d'
}

@font-face {
font-family: '__degularDisplayBlackItalic_c5ec6b';
src: url(/_next/static/media/933de421f984baac-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__degularDisplayBlackItalic_Fallback_c5ec6b';src: local("Arial");ascent-override: 107.04%;descent-override: 40.35%;line-gap-override: 0.00%;size-adjust: 89.96%
}.__className_c5ec6b {font-family: '__degularDisplayBlackItalic_c5ec6b', '__degularDisplayBlackItalic_Fallback_c5ec6b'
}.__variable_c5ec6b {--font-degular-display-black-italic: '__degularDisplayBlackItalic_c5ec6b', '__degularDisplayBlackItalic_Fallback_c5ec6b'
}

@font-face {
font-family: '__degularDisplayBoldItalic_6f659d';
src: url(/_next/static/media/2a4137e39032c22e-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplayBoldItalic_Fallback_6f659d';src: local("Arial");ascent-override: 108.05%;descent-override: 40.73%;line-gap-override: 0.00%;size-adjust: 89.12%
}.__className_6f659d {font-family: '__degularDisplayBoldItalic_6f659d', '__degularDisplayBoldItalic_Fallback_6f659d'
}.__variable_6f659d {--font-degular-display-bold-italic: '__degularDisplayBoldItalic_6f659d', '__degularDisplayBoldItalic_Fallback_6f659d'
}

@font-face {
font-family: '__degularDisplayMedium_d6f900';
src: url(/_next/static/media/a60b07234e12b30f-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplayMedium_Fallback_d6f900';src: local("Arial");ascent-override: 110.75%;descent-override: 41.75%;line-gap-override: 0.00%;size-adjust: 86.95%
}.__className_d6f900 {font-family: '__degularDisplayMedium_d6f900', '__degularDisplayMedium_Fallback_d6f900'
}.__variable_d6f900 {--font-degular-display-medium: '__degularDisplayMedium_d6f900', '__degularDisplayMedium_Fallback_d6f900'
}

@font-face {
font-family: '__degularDisplayMediumItalic_17c907';
src: url(/_next/static/media/d785c20cb4b66c42-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplayMediumItalic_Fallback_17c907';src: local("Arial");ascent-override: 109.54%;descent-override: 41.29%;line-gap-override: 0.00%;size-adjust: 87.92%
}.__className_17c907 {font-family: '__degularDisplayMediumItalic_17c907', '__degularDisplayMediumItalic_Fallback_17c907'
}.__variable_17c907 {--font-degular-display-bold-italic: '__degularDisplayMediumItalic_17c907', '__degularDisplayMediumItalic_Fallback_17c907'
}

@font-face {
font-family: '__degularDisplayRegular_8a0dc7';
src: url(/_next/static/media/cf5f95087776fb79-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__degularDisplayRegular_Fallback_8a0dc7';src: local("Arial");ascent-override: 111.40%;descent-override: 41.99%;line-gap-override: 0.00%;size-adjust: 86.44%
}.__className_8a0dc7 {font-family: '__degularDisplayRegular_8a0dc7', '__degularDisplayRegular_Fallback_8a0dc7'
}.__variable_8a0dc7 {--font-degular-display-regular: '__degularDisplayRegular_8a0dc7', '__degularDisplayRegular_Fallback_8a0dc7'
}

@font-face {
font-family: '__degularDisplayBoldVi_2371ba';
src: url(/_next/static/media/9f2330e48d3667fc-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayBoldVi_Fallback_2371ba';src: local("Arial");ascent-override: 132.28%;descent-override: 37.31%;line-gap-override: 0.00%;size-adjust: 88.45%
}.__className_2371ba {font-family: '__degularDisplayBoldVi_2371ba', '__degularDisplayBoldVi_Fallback_2371ba'
}.__variable_2371ba {--font-degular-display-bold: '__degularDisplayBoldVi_2371ba', '__degularDisplayBoldVi_Fallback_2371ba'
}

@font-face {
font-family: '__degularDisplaySemiboldVi_a0559f';
src: url(/_next/static/media/a02fe6ae89a92cf7-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplaySemiboldVi_Fallback_a0559f';src: local("Arial");ascent-override: 133.52%;descent-override: 37.66%;line-gap-override: 0.00%;size-adjust: 87.63%
}.__className_a0559f {font-family: '__degularDisplaySemiboldVi_a0559f', '__degularDisplaySemiboldVi_Fallback_a0559f'
}.__variable_a0559f {--font-degular-display-semibold: '__degularDisplaySemiboldVi_a0559f', '__degularDisplaySemiboldVi_Fallback_a0559f'
}

@font-face {
font-family: '__degularDisplayBlackItalicVi_05bc9e';
src: url(/_next/static/media/72dd15113e1dc431-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayBlackItalicVi_Fallback_05bc9e';src: local("Arial");ascent-override: 141.17%;descent-override: 36.68%;line-gap-override: 0.00%;size-adjust: 89.96%
}.__className_05bc9e {font-family: '__degularDisplayBlackItalicVi_05bc9e', '__degularDisplayBlackItalicVi_Fallback_05bc9e'
}.__variable_05bc9e {--font-degular-display-black-italic: '__degularDisplayBlackItalicVi_05bc9e', '__degularDisplayBlackItalicVi_Fallback_05bc9e'
}

@font-face {
font-family: '__degularDisplayBoldItalicVi_4b2082';
src: url(/_next/static/media/fe104b048876a4bb-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayBoldItalicVi_Fallback_4b2082';src: local("Arial");ascent-override: 131.28%;descent-override: 37.03%;line-gap-override: 0.00%;size-adjust: 89.12%
}.__className_4b2082 {font-family: '__degularDisplayBoldItalicVi_4b2082', '__degularDisplayBoldItalicVi_Fallback_4b2082'
}.__variable_4b2082 {--font-degular-display-bold-italic: '__degularDisplayBoldItalicVi_4b2082', '__degularDisplayBoldItalicVi_Fallback_4b2082'
}

@font-face {
font-family: '__degularDisplayMediumVi_69d2ca';
src: url(/_next/static/media/d2e9e9536ce43168-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayMediumVi_Fallback_69d2ca';src: local("Arial");ascent-override: 134.56%;descent-override: 37.95%;line-gap-override: 0.00%;size-adjust: 86.95%
}.__className_69d2ca {font-family: '__degularDisplayMediumVi_69d2ca', '__degularDisplayMediumVi_Fallback_69d2ca'
}.__variable_69d2ca {--font-degular-display-medium: '__degularDisplayMediumVi_69d2ca', '__degularDisplayMediumVi_Fallback_69d2ca'
}

@font-face {
font-family: '__degularDisplayMediumItalicVi_2b9d20';
src: url(/_next/static/media/313357acfe3863c0-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayMediumItalicVi_Fallback_2b9d20';src: local("Arial");ascent-override: 133.08%;descent-override: 37.54%;line-gap-override: 0.00%;size-adjust: 87.92%
}.__className_2b9d20 {font-family: '__degularDisplayMediumItalicVi_2b9d20', '__degularDisplayMediumItalicVi_Fallback_2b9d20'
}.__variable_2b9d20 {--font-degular-display-medium-italic: '__degularDisplayMediumItalicVi_2b9d20', '__degularDisplayMediumItalicVi_Fallback_2b9d20'
}

@font-face {
font-family: '__degularDisplayRegularVi_610fe4';
src: url(/_next/static/media/18c0ce0dc5b36d9f-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__degularDisplayRegularVi_Fallback_610fe4';src: local("Arial");ascent-override: 135.35%;descent-override: 38.18%;line-gap-override: 0.00%;size-adjust: 86.44%
}.__className_610fe4 {font-family: '__degularDisplayRegularVi_610fe4', '__degularDisplayRegularVi_Fallback_610fe4'
}.__variable_610fe4 {--font-degular-display-regular: '__degularDisplayRegularVi_610fe4', '__degularDisplayRegularVi_Fallback_610fe4'
}

@font-face {
font-family: '__dMSansRegular_e6b8dd';
src: url(/_next/static/media/e7b7e0b18087fd37-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__dMSansRegular_Fallback_e6b8dd';src: local("Arial");ascent-override: 94.48%;descent-override: 29.53%;line-gap-override: 0.00%;size-adjust: 104.99%
}.__className_e6b8dd {font-family: '__dMSansRegular_e6b8dd', '__dMSansRegular_Fallback_e6b8dd'
}.__variable_e6b8dd {--font-dm_sans: '__dMSansRegular_e6b8dd', '__dMSansRegular_Fallback_e6b8dd'
}

